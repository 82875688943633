const HRManagement = {
  route: null,
  name: null,
  title: '人事管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'User',
  isElementIcon: true,
  inNav: true,
  children: [
    {
      title: '员工花名册',
      type: 'view',
      route: '/hr-management/list',
      name: Symbol('hr-management-list'),
      filePath: 'view/hr-management/outsourced-employee-list.vue',
      inNav: true,
      icon: '',
    },
    {
      title: '入职管理',
      type: 'folder', // 类型: folder, tab, view
      name: Symbol('OutsourcedEmployee'),
      inNav: true,
      icon: '',
      children: [
        {
          title: '入职登记',
          type: 'view', // 类型: folder, tab, view
          name: Symbol('EmployeeCollectionAdded'),
          route: '/hr-management/collection-added-list',
          filePath: 'view/hr-management/employee-collection/employee-collection-added-list.vue',
          inNav: true,
          icon: '',
        },
        {
          title: '入职登记',
          type: 'view', // 类型: folder, tab, view
          name: Symbol('EmployeeAdd'),
          route: '/hr-management/employee-add',
          filePath: 'view/hr-management/entry/employee-add.vue',
          inNav: false,
          icon: '',
        },
        {
          title: '入职审批',
          type: 'view',
          name: Symbol('OutsourcedEmployeeEntryReviewList'),
          route: '/hr-management/entry-review-list',
          filePath: 'view/hr-management/entry/entry-review-list.vue',
          inNav: true,
          icon: '',
        },
      ],
    },
    {
      title: '员工信息收集',
      type: 'folder', // 类型: folder, tab, view
      name: Symbol('EmployeeCollection'),
      inNav: true,
      icon: '',
      children: [
        {
          title: '员工信息收集',
          type: 'view', // 类型: folder, tab, view
          name: Symbol('EmployeeCollection'),
          route: '/hr-management/collection-list',
          filePath: 'view/hr-management/employee-collection/employee-collection-list.vue',
          inNav: true,
          icon: '',
        },
        {
          title: '信息收集表配置',
          type: 'view', // 类型: folder, tab, view
          name: Symbol('EmployeeCollectionConfigList'),
          route: '/hr-management/collect-config-list',
          filePath: 'view/hr-management/employee-collection/collect-config-list.vue',
          inNav: true,
          icon: '',
        },
        {
          title: '设置信息收集',
          type: 'view', // 类型: folder, tab, view
          name: Symbol('EmployeeCollectionConfig'),
          route: '/hr-management/collect-config',
          filePath: 'view/hr-management/employee-collection/collect-config.vue',
          inNav: false,
          icon: '',
        },
      ],
    },
    {
      title: '离职管理',
      type: 'folder', // 类型: folder, tab, view
      name: Symbol('OutsourcedEmployeeDimissionManager'),
      inNav: true,
      icon: '',
      children: [
        {
          title: '离职发起',
          type: 'view',
          name: Symbol('OutsourcedEmployeeDimissionList'),
          route: '/hr-management/dimission-list',
          filePath: 'view/hr-management/employee-dimission/outsourced-employee-dimission-list.vue',
          inNav: true,
          icon: '',
        },
        {
          title: '离职审批',
          type: 'view',
          name: Symbol('OutsourcedEmployeeDimissionReview'),
          route: '/hr-management/dimission-review',
          filePath: 'view/hr-management/employee-dimission/outsourced-employee-dimission-review.vue',
          inNav: true,
          icon: '',
        },
      ]
    },
    {
      title: '异动管理',
      type: 'folder',
      name: Symbol('OutsourcedEmployeeTransaction'),
      inNav: false,
      icon: '',
      children: [
        {
          title: '异动发起',
          type: 'view',
          name: Symbol('OutsourcedEmployeeTransactionList'),
          route: '/hr-management/transaction-list',
          filePath: 'view/hr-management/outsourced-employee-transaction-list.vue',
          inNav: true,
          icon: '',
        },
        {
          title: '异动审批',
          type: 'view',
          name: Symbol('OutsourcedEmployeeTransactionHistoryList'),
          route: '/hr-management/transaction-history-list',
          filePath: 'view/hr-management/outsourced-employee-transaction-history-list.vue',
          inNav: true,
          icon: '',
        },
      ],
    },
  ],
}

export default HRManagement
